import React from 'react'
import logo_gray from '../../images/lendis-gray.png'
import green_check from '../../images/green-check.svg'
import black_check from '../../images/black-check.svg'
import red_minus from '../../images/red-minus.svg'

const Compare = () => {
  return (
    <div className="md:pb-40 pb-12">
      <div className="container mx-auto">
        <div className="flex flex-wrap">
          <div className="flex-1 px-2">
            <div className="text-center bg-lendis-black text-white text-lendis-light-black px-8">
              <div className="py-16">
                <img className="h-12 mx-auto" src={logo_gray} alt="" />
              </div>
              <div>
                <div className="flex xxl:pb-10 xl:pb-16 lg:pb-16 pb-10 text-left">
                  <img src={green_check} alt="" />
                  <p className="pl-5 text-white">Hohe Flexibilität</p>
                </div>

                <div className="flex xxl:pb-10 xl:pb-16 lg:pb-16 pb-10 text-left">
                  <img src={green_check} alt="" />
                  <p className="pl-5 text-white">Keine Vorabinvestitionen</p>
                </div>

                <div className="flex xxl:pb-10 xl:pb-16 lg:pb-16 pb-10 text-left">
                  <img src={green_check} alt="" />
                  <p className="pl-5 text-white">Full-Service inklusive</p>
                </div>

                <div className="flex xxl:pb-10 xl:pb-16 lg:pb-16 pb-10 text-left">
                  <img src={green_check} alt="" />
                  <p className="pl-5 text-white">One-Stop Shop</p>
                </div>

                <div className="flex xxl:pb-10 xl:pb-16 lg:pb-16 pb-10 text-left">
                  <img src={green_check} alt="" />
                  <p className="pl-5 text-white">Niedrige Betriebskosten</p>
                </div>
              </div>
            </div>
            <div className="bg-lendis-main p-6 text-center text-white">
              <p>Beste Option für Konzerne, KMUs und Wachstumsunternehmen</p>
            </div>
          </div>

          <div className="flex-1 px-2 hidden lg:block">
            <div className="text-center bg-lendis-light-gray text-lendis-light-black px-8">
              <div className="py-16">
                <h3 className="text-3xl h-12">Coworking</h3>
              </div>
              <div>
                <div className="flex xxl:pb-10 xl:pb-16 lg:pb-16 text-left">
                  <img src={black_check} alt="" />
                  <p className="pl-5">Hohe Flexibilität</p>
                </div>

                <div className="flex xxl:pb-10 xl:pb-16 lg:pb-16 text-left">
                  <img src={black_check} alt="" />
                  <p className="pl-5">Keine Vorabinvestitionen</p>
                </div>

                <div className="flex xxl:pb-10 xl:pb-16 lg:pb-16 text-left">
                  <img src={black_check} alt="" />
                  <p className="pl-5">Full-Service inklusive</p>
                </div>

                <div className="flex xxl:pb-10 xl:pb-10 lg:pb-10 text-left">
                  <img src={red_minus} alt="" />
                  <p className="pl-5">Keine Elektronik und Software</p>
                </div>

                <div className="flex xxl:pb-10 xl:pb-16 lg:pb-16 text-left">
                  <img src={red_minus} alt="" />
                  <p className="pl-5">Hohe Betriebskosten</p>
                </div>
              </div>
            </div>
            <div className="bg-lendis-medium-gray p-6 text-center">
              <p>Beste Option, wenn keine Bürofläche gefunden wird</p>
            </div>
          </div>

          <div className="flex-1 px-2 hidden lg:block">
            <div className="text-center bg-lendis-light-gray text-lendis-light-black px-8">
              <div className="py-16">
                <h3 className="text-3xl h-12">Leasing</h3>
              </div>
              <div>
                <div className="flex xxl:pb-10 xl:pb-6 lg:pb-10 text-left">
                  <img src={red_minus} alt="" />
                  <p className="pl-5">An den Vertrag gebunden</p>
                </div>

                <div className="flex xxl:pb-10 xl:pb-16 lg:pb-16 text-left">
                  <img src={red_minus} alt="" />
                  <p className="pl-5">i.d.R. Anzahlung notwendig</p>
                </div>

                <div className="flex xxl:pb-10 xl:pb-16 lg:pb-16 text-left">
                  <img src={red_minus} alt="" />
                  <p className="pl-5">i.d.R. ohne Service </p>
                </div>

                <div className="flex xxl:pb-5 xl:pb-8 pb-4 text-left">
                  <img src={red_minus} alt="" />
                  <p className="pl-5">Mehrere Lieferanten notwendig</p>
                </div>

                <div className="flex xxl:pb-10 xl:pb-16 lg:pb-16 text-left">
                  <img src={black_check} alt="" />
                  <p className="pl-5">Niedrige Betriebskosten</p>
                </div>
              </div>
            </div>
            <div className="bg-lendis-medium-gray px-6 py-3 text-center">
              <p>Beste Option für reine Finanzierung von Spezialmaschinen</p>
            </div>
          </div>

          <div className="flex-1 px-2 hidden lg:block">
            <div className="text-center bg-lendis-light-gray text-lendis-light-black px-8">
              <div className="py-16">
                <h3 className="text-3xl h-12">Kauf</h3>
              </div>
              <div>
                <div className="flex xxl:pb-5 xl:pb-10 pb-4 text-left">
                  <img src={red_minus} alt="" />
                  <p className="pl-5">Notwendigkeit des Weiterverkaufs</p>
                </div>

                <div className="flex xxl:pb-10 xl:pb-16 lg:pb-16 text-left">
                  <img src={red_minus} alt="" />
                  <p className="pl-5">Hohes Investment</p>
                </div>

                <div className="flex xxl:pb-10 xl:pb-16 lg:pb-16 text-left">
                  <img src={red_minus} alt="" />
                  <p className="pl-5">i.d.R. ohne Service </p>
                </div>

                <div className="flex xxl:pb-5 xl:pb-10 pb-4 text-left">
                  <img src={red_minus} alt="" />
                  <p className="pl-5">Mehrere Lieferanten notwendig</p>
                </div>

                <div className="flex xxl:pb-10 xl:pb-16 lg:pb-16 text-left">
                  <img src={black_check} alt="" />
                  <p className="pl-5">Kaum Betriebskosten</p>
                </div>
              </div>
            </div>
            <div className="bg-lendis-medium-gray p-6 text-center">
              <p>Beste Option bei komplexen Sonderanfertigungen</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Compare
