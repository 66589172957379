import React from 'react'
import Image from '../Lendis/Image'

const BenefitFlow = () => {
  return (
    <div className="container mx-auto">
      <div className="flex flex-wrap">
        <div className="w-4/12 lg:w-full lg:flex lg:flex-wrap">
          <div className="lg:w-1/4 lg:px-2">
            <div className="bg-lendis-main h-32 pl-3 sm:pl-6 pt-10 text-base text-white lg:mt-2 lg:h-40 lg:pt-16 lg:text-lg">
              <div className="pb-3 pl-1">
                <svg
                  width="22"
                  height="24"
                  viewBox="0 0 22 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="lg:w-8 lg:h-8"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 2.25C0 1.00736 0.984969 0 2.2 0H19.8C21.015 0 22 1.00736 22 2.25C22 2.91637 21.7168 3.51509 21.2667 3.92708V18.774C21.2667 20.8029 20.3406 21.7501 18.3568 21.75H3.64321C1.66055 21.7501 0.733333 20.8017 0.733333 18.774V3.92708C0.283236 3.51509 0 2.91637 0 2.25ZM20.5333 2.25C20.5333 2.66421 20.205 3 19.8 3H2.2C1.79502 3 1.46667 2.66421 1.46667 2.25C1.46667 1.83579 1.79502 1.5 2.2 1.5H19.8C20.205 1.5 20.5333 1.83579 20.5333 2.25ZM2.2 18.75V4.5H10.2667V20.25H3.66667C2.47733 20.25 2.2 19.958 2.2 18.75ZM11.7333 20.25H18.3333C19.5322 20.25 19.8 19.9762 19.8 18.75V4.5H11.7333V20.25Z"
                    fill="white"
                  />
                  <path
                    d="M2.2 22.5C1.79502 22.5 1.46667 22.8358 1.46667 23.25C1.46667 23.6642 1.79502 24 2.2 24H4.13333C4.68565 24 5.13333 23.5421 5.13333 22.9773V22.5H2.2Z"
                    fill="white"
                  />
                  <path
                    d="M19.8 22.5C20.205 22.5 20.5333 22.8358 20.5333 23.25C20.5333 23.6642 20.205 24 19.8 24H17.7128C17.2455 24 16.8667 23.6126 16.8667 23.1346V22.5H19.8Z"
                    fill="white"
                  />
                  <path
                    d="M8.33333 9.66667C8.33333 9.29848 8.03483 9 7.66667 9C7.2985 9 7 9.29848 7 9.66667V12.3333C7 12.7015 7.2985 13 7.66667 13C8.03483 13 8.33333 12.7015 8.33333 12.3333V9.66667Z"
                    fill="white"
                  />
                  <path
                    d="M15 9.66667C15 9.29848 14.7015 9 14.3333 9C13.9652 9 13.6667 9.29848 13.6667 9.66667V12.3333C13.6667 12.7015 13.9652 13 14.3333 13C14.7015 13 15 12.7015 15 12.3333V9.66667Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div>
                <p className="">Auswahl</p>
              </div>
            </div>
          </div>

          <div className="lg:w-1/4 lg:px-2">
            <div className="bg-lendis-black h-32 mt-2 pl-3 sm:pl-6 pt-10 text-base text-white lg:h-40 lg:pt-16 lg:text-lg">
              <div className="pb-3 pl-1">
                <Image src="website/16_dke0xd.png" alt="Aufbau" fixSize="33" />
              </div>
              <div>
                <p className="">Aufbau</p>
              </div>
            </div>
          </div>

          <div className="lg:w-1/4 lg:px-2">
            <div className="bg-lendis-main h-32 pl-3 sm:pl-6 pt-10 text-base text-white mt-2 lg:h-40 lg:pt-16 lg:text-lg">
              <div className="pb-3 pl-1">
                <svg
                  width="26"
                  height="24"
                  viewBox="0 0 26 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="lg:w-8 lg:h-8"
                >
                  <path
                    d="M7.80401 0.857143C7.80401 0.383756 8.19177 0 8.67001 0H17.33C17.8082 0 18.196 0.383756 18.196 0.857143C18.196 1.33053 17.8082 1.71429 17.33 1.71429H8.67001C8.19177 1.71429 7.80401 1.33053 7.80401 0.857143Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.04126 3.42857H19.9587C21.0545 3.42856 22.2806 3.32996 23.317 3.7548C24.378 4.18977 25.2209 5.02409 25.6604 6.07422C26.0897 7.09987 25.99 8.31371 25.99 9.39812V20.5988C25.99 22.8334 24.8114 24.0001 22.5537 24H3.44633C1.18861 24.0001 0.0100363 22.8334 0.0100363 20.5988V9.39813C0.0100363 8.31372 -0.089701 7.09988 0.339648 6.07422C0.779096 5.02409 1.62205 4.18977 2.68298 3.7548C3.71937 3.32996 4.94554 3.42856 6.04126 3.42857ZM3.3458 5.3386C2.7092 5.59958 2.20347 6.10017 1.93982 6.73024C1.70028 7.30245 1.74814 8.00604 1.74383 8.61328C5.30053 9.7002 9.08039 10.2857 13 10.2857C16.9196 10.2857 20.6995 9.70022 24.2562 8.61331C24.2519 8.00607 24.2997 7.30246 24.0602 6.73024C23.7965 6.10017 23.2908 5.59958 22.6542 5.3386C21.8434 5.00629 20.778 5.14286 19.928 5.14286H6.07202C5.222 5.14286 4.1566 5.00629 3.3458 5.3386ZM24.258 10.4032C20.6871 11.4426 16.9086 12 13 12C9.09149 12 5.313 11.4426 1.74203 10.4032V20.5714C1.74203 21.9201 2.11144 22.2857 3.47403 22.2857H22.526C23.8886 22.2857 24.258 21.9201 24.258 20.5714V10.4032Z"
                    fill="white"
                  />
                  <path
                    d="M10 15.5C10 15.2239 10.3731 15 10.8333 15H14.1667C14.6269 15 15 15.2239 15 15.5C15 15.7761 14.6269 16 14.1667 16H10.8333C10.3731 16 10 15.7761 10 15.5Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div>
                <p className="">Verwaltung</p>
              </div>
            </div>
          </div>

          <div className="lg:w-1/4 lg:px-2">
            <div className="bg-lendis-black h-32 mt-2 pl-3 sm:pl-6 pt-10 text-base text-white lg:h-40 lg:pt-16 lg:text-lg">
              <div className="pb-3 pl-1">
                <svg
                  width="25"
                  height="23"
                  viewBox="0 0 25 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="lg:w-8 lg:h-8"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.7015 1.09945L23.8056 2.45785C24.6655 3.51577 24.8057 5.03606 24.1867 6.2594C20.5136 13.5187 14.6744 18.9422 7.57472 22.6625C6.30931 23.3256 4.81142 22.9854 3.9067 21.8723C3.4552 21.3168 2.92803 20.7812 2.56448 20.1588C1.67841 18.6418 2.06329 16.6017 3.48398 15.583L7.54941 12.6679C8.31829 12.1166 9.33812 12.2109 10.0008 12.8847L10.6403 13.5349C12.5994 12.3829 14.1855 10.7946 15.4252 8.73218L14.8379 7.72593C14.4029 6.98069 14.4525 6.02142 14.9617 5.32649L17.9492 1.24966C19.1069 -0.330212 21.4444 -0.447125 22.7015 1.09945ZM21.4125 2.18067C20.8366 1.47209 19.8141 1.5486 19.2901 2.26367L16.3026 6.34051C15.93 6.84903 16.8756 7.8875 17.1152 8.2979C17.2684 8.56036 17.2711 8.88615 17.1224 9.15128C15.599 11.8677 13.5055 13.9478 10.879 15.3389C10.5552 15.5104 10.1594 15.4485 9.9013 15.1861L8.81822 14.0849C8.72635 13.9915 8.61215 13.9845 8.51339 14.0553L4.44796 16.9704C3.7689 17.4573 3.54248 18.5054 4.00208 19.2923C4.31935 19.8354 4.80118 20.3057 5.19571 20.7911C5.61698 21.3094 6.26573 21.4376 6.8084 21.1533C13.6001 17.5944 19.1855 12.4291 22.7 5.48308C23.0237 4.84345 22.9372 4.0565 22.5166 3.53907L21.4125 2.18067Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.52047 5.83111C7.4829 5.83111 5.83112 7.48289 5.83112 9.52047C5.83112 10.0363 5.41292 10.4545 4.89705 10.4545C4.38117 10.4545 3.96297 10.0363 3.96297 9.52047C3.96297 6.45114 6.45115 3.96296 9.52047 3.96296C10.0363 3.96296 10.4545 4.38116 10.4545 4.89703C10.4545 5.41291 10.0363 5.83111 9.52047 5.83111Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.52044 1.86815C5.2942 1.86815 1.86815 5.29421 1.86815 9.52046C1.86815 10.0363 1.44995 10.4545 0.934074 10.4545C0.418199 10.4545 0 10.0363 0 9.52046C0 4.26245 4.26245 0 9.52044 0C10.0363 0 10.4545 0.4182 10.4545 0.934076C10.4545 1.44995 10.0363 1.86815 9.52044 1.86815Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div>
                <p className="">Support</p>
              </div>
            </div>
          </div>
        </div>

        <div className=" lg:w-full lg:flex lg:flex-wrap w-7/12">
          <div className="lg:w-1/4 lg:px-2">
            <div className="h-32 mt-2 lg:pt-8 pl-3 md:pt-12 pt-4 sm:pl-6">
              <div>
                <p className="">Umfassendes Angebot, kompetente Beratung, schnelle Entscheidung</p>
              </div>
            </div>
          </div>

          <div className="lg:w-1/4 lg:px-2">
            <div className="h-32 mt-2 lg:pt-8 pl-3 md:pt-12 pt-4 sm:pl-6">
              <div>
                <p className="">
                  Schnelle Lieferung, sicherer Aufbau, vorinstallierte Software,
                  abgestimmte Services
                </p>
              </div>
            </div>
          </div>

          <div className="lg:w-1/4 lg:px-2">
            <div className="h-32 mt-2 lg:pt-8 pl-3 md:pt-12 pt-4 sm:pl-6">
              <div>
                <p className="">
                  Volle Flexibilität, einfache Nachbestellung – ein Vertrag für alles
                </p>
              </div>
            </div>
          </div>

          <div className="lg:w-1/4 lg:px-2">
            <div className="h-32 mt-2 lg:pt-8 pl-3 md:pt-12 pt-4 sm:pl-6">
              <div>
                <p>Zuverlässige Inklusivleistungen, gut versichert, eine Anlaufstelle</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden lg:block">
        <div className="lg:mt-16 xl:mt-10 my-10 relative">
          <div className="-mt-16 absolute right-0 z-10">
            <Image
              src="website/Lendis-Dashboard-Screen1_dsqvwk.png"
              alt="Lendis-Dashboard"
              noChange
            />
          </div>
          <div className="relative">
            <svg
              width="1434"
              height="42"
              viewBox="0 0 1434 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-full"
            >
              <rect
                x="2"
                y="23"
                width="8"
                height="1280"
                transform="rotate(-90 2 23)"
                fill="#00AF54"
              />
              <circle cx="371" cy="21" r="21" fill="#00AF54" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M360.519 19.7909C361.212 19.1231 362.334 19.1231 363.026 19.7909L367.258 23.8719L376.974 14.5008C377.666 13.8331 378.788 13.8331 379.481 14.5008C380.173 15.1686 380.173 16.2513 379.481 16.919L368.511 27.4992C367.819 28.1669 366.696 28.1669 366.004 27.4992L360.519 22.2091C359.827 21.5413 359.827 20.4587 360.519 19.7909Z"
                fill="white"
              />
              <circle cx="720" cy="21" r="21" fill="#00AF54" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M710.493 19.7909C711.151 19.1231 712.217 19.1231 712.875 19.7909L716.895 23.8719L726.125 14.5008C726.783 13.8331 727.849 13.8331 728.507 14.5008C729.164 15.1686 729.164 16.2513 728.507 16.919L718.086 27.4992C717.428 28.1669 716.362 28.1669 715.704 27.4992L710.493 22.2091C709.836 21.5413 709.836 20.4587 710.493 19.7909Z"
                fill="white"
              />
              <circle cx="1077" cy="21" r="21" fill="#00AF54" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1066.52 19.7909C1067.21 19.1231 1068.33 19.1231 1069.03 19.7909L1073.26 23.8719L1082.97 14.5008C1083.67 13.8331 1084.79 13.8331 1085.48 14.5008C1086.17 15.1686 1086.17 16.2513 1085.48 16.919L1074.51 27.4992C1073.82 28.1669 1072.7 28.1669 1072 27.4992L1066.52 22.2091C1065.83 21.5413 1065.83 20.4587 1066.52 19.7909Z"
                fill="white"
              />
              <circle cx="21" cy="21" r="21" fill="#00AF54" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.4933 19.7909C12.151 19.1231 13.2174 19.1231 13.8751 19.7909L17.8947 23.8719L27.1249 14.5008C27.7826 13.8331 28.849 13.8331 29.5067 14.5008C30.1644 15.1686 30.1644 16.2513 29.5067 16.919L19.0857 27.4992C18.4279 28.1669 17.3615 28.1669 16.7038 27.4992L11.4933 22.2091C10.8356 21.5413 10.8356 20.4587 11.4933 19.7909Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div className="flex flex-row-reverse mr-4 mb-10">
          <Image alt="lendis-logo" src="website/LendisLogo_vf4d0e.svg" className="w-56" />
          <h3 className="mr-10 text-3xl">Office Management mit Lendis </h3>
        </div>

        <div>
          <div>
            <div className=" lg:w-full lg:flex lg:flex-wrap">
              <div className="lg:w-1/4 lg:px-2">
                <div className="lg:pt-8 pl-3 pt-20 sm:pl-6">
                  <div>
                    <p>
                      Wochenlange Suche nach der richtigen Ausstattung, einseitige Beratung, teure
                      Investments
                    </p>
                  </div>
                </div>
              </div>

              <div className="lg:w-1/4 lg:px-2">
                <div className=" mt-2 lg:pt-8 pl-3 pt-20 sm:pl-6">
                  <div>
                    <p>Lange Lieferzeiten, großer Koordinationsbedarf, viel eigenhändige Arbeit</p>
                  </div>
                </div>
              </div>

              <div className="lg:w-1/4 lg:px-2">
                <div className=" mt-2 lg:pt-8 pl-3 pt-20 sm:pl-6">
                  <div>
                    <p>Großer Verwaltungsaufwand, viele Verträge, unflexible Bedingungen</p>
                  </div>
                </div>
              </div>

              <div className="lg:w-1/4 lg:px-2">
                <div className=" mt-2 lg:pt-8 pl-3 pt-20 sm:pl-6">
                  <div>
                    <p>Teure Reparaturen, komplizierter Austausch, großer Koordinationsaufwand</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <svg
              width="1425"
              height="103"
              viewBox="0 0 1425 103"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-full"
            >
              <path
                d="M20 49.642C20 49.642 67.9202 19.1851 173.718 11.1395C279.516 3.09399 390.915 47.9197 397.761 49.642C404.606 51.3643 460.617 96.7674 557.702 100.79C654.787 104.813 778.633 49.642 778.633 49.642C778.633 49.642 857.67 8.84109 951.021 2.51962C1044.37 -3.80185 1112.83 49.6424 1112.83 49.6424C1112.83 49.6424 1182.53 91.5922 1267.79 49.642C1353.05 7.69184 1392.88 22.6333 1424 49.642"
                stroke="#222222"
                strokeOpacity="0.7"
                strokeWidth="3"
              />
              <circle cx="370.5" cy="43.5" r="21.5" fill="#222222" />
              <ellipse cx="720.5" cy="67" rx="21.5" ry="21" fill="#222222" />
              <ellipse cx="1078" cy="31.5" rx="21" ry="21.5" fill="#222222" />
              <ellipse cx="21.5" cy="50" rx="21.5" ry="21" fill="#222222" />
              <path
                d="M10 51C10 49.3431 11.3431 48 13 48H31C32.6569 48 34 49.3431 34 51V51C34 52.6569 32.6569 54 31 54H13C11.3431 54 10 52.6569 10 51V51Z"
                fill="white"
              />
              <path
                d="M359 44C359 42.3431 360.343 41 362 41H379C380.657 41 382 42.3431 382 44V44C382 45.6569 380.657 47 379 47H362C360.343 47 359 45.6569 359 44V44Z"
                fill="white"
              />
              <path
                d="M708 68C708 66.3431 709.343 65 711 65H729C730.657 65 732 66.3431 732 68V68C732 69.6569 730.657 71 729 71H711C709.343 71 708 69.6569 708 68V68Z"
                fill="white"
              />
              <path
                d="M1064 32.5C1064 31.1193 1065.12 30 1066.5 30H1086.5C1087.88 30 1089 31.1193 1089 32.5V32.5C1089 33.8807 1087.88 35 1086.5 35H1066.5C1065.12 35 1064 33.8807 1064 32.5V32.5Z"
                fill="white"
              />
            </svg>
          </div>
        </div>

        <div className="flex flex-row-reverse mr-4 mb-10">
          <h3 className=" text-2xl">Traditionelles Office Management </h3>
        </div>
      </div>
    </div>
  )
}

export default BenefitFlow
