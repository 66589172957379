import React from 'react'
import Layout from '../components/layout'
import Footer from '../components/Footer'
import Menu from '../components/Menu'
import BenefitSection from '../components/Benefit/BenefitSection'
import ProcessBenefit from '../components/Benefit/ProcessBenefit'
import Compare from '../components/Benefit/Compare'
import ConsumerCarousel from '../components/Share/ConsumerCarousel'
import BenefitFlow from '../components/Benefit/BenefitFlow'
import Title from '../components/Share/Title'
import Header from '../components/Header'
import BreadCrumb from '../components/Share/BreadCrumb'
const breadcrumb = [
  {
    link: '/',
    title: 'Home',
  },
  {
    title: 'Vorteile',
  },
]

const Benefits = () => {
  const benefit =
    process.env.CLOUDINARY_URL +
    '/f_auto/website/15b_1920_darker_achievement-3408115_1920_dkraeo.jpg'

  return (
    <Layout>
      <Header page="benefits" />
      <Menu />

      <div
        className="big-background-image pb-8 bg-center bg-lendis-main bg-no-repeat bg-top g-contain lg:pb-40 pt-40 px-3"
        style={{ backgroundImage: `url(${benefit})` }}
      >
        <div className="container mx-auto text-white">
          <h1 className="font-bold lg:text-6xl px-8 text-3xl mb-5">Vorteile</h1>
          <p className="font-semibold lg:text-2xl mb-10 mb-5 px-8 text-xl ">
            Lieber mieten statt kaufen
          </p>
        </div>
        <div className="bg-white container mx-auto lg:px-10 ">
          <BreadCrumb breadCrumbItems={breadcrumb} />
        </div>
        <BenefitSection />
      </div>

      <div className="bg-lendis-light-gray pb-16">
        <div className="bg-lendis-light-gray container mx-auto px-10">
          <Title
            bgColor="bg-lendis-light-gray"
            title="Office Management mit Lendis"
            header="VOLLER KOMFORT"
            right
            titleSize="text-3xl"
          />
        </div>

        <BenefitFlow />
      </div>

      <div className="bg-white container mx-auto px-10">
        <Title title="Warum Lendis" header="VERGLEICH" right titleSize="text-3xl" />
      </div>

      <Compare />

      <ProcessBenefit />

      <ConsumerCarousel />

      <Footer />
    </Layout>
  )
}
export default Benefits
