import React from 'react'
import SquareSection from '../Share/SquareSection'

const BenefitSection = () => {
  return (
    <SquareSection
      smallTitle="IHRE VORTEILE"
      title="Mieten statt kaufen?"
      content={[
        {
          title: 'Cashflow',
          description:
            'Beziehen Sie Ihre Büroausstattung zu niedrigen monatlichen Raten und ohne hohe Anfangsinvestitionen. Nutzen Sie stattdessen Ihr Kapital für das Wachstum Ihres Unternehmens.',
          image: 'website/cashflow_cwieal.svg',
          imageSize: '51',
          width: '2',
          className: 'my-4',
          numberTitle: '01',
        },
        {
          title: 'Flexibilität',
          description:
            'Mieten Sie die Büroausstattung so lange wie nötig oder kündigen Sie den Mietvertrag – mit Lendis sind Sie flexibel. Reagieren Sie mit Leichtigkeit auf kurzfristige Veränderungen und Wachstumspläne.',
          image: 'website/Flexibilitaet_laahgn.svg',
          imageSize: '51',
          width: '2',
          className: 'my-4',
          numberTitle: '02',
        },
        {
          title: 'Service',
          description:
            'Profitieren Sie von unserem Rundum-Service inklusive Grundriss- und Interior-Planung, Lieferung und Montage sowie kostenloser Versicherung der Möbel und Geräte.',
          image: 'website/services2_dcypuc.svg',
          imageSize: '51',
          width: '2',
          className: 'my-4',
          numberTitle: '03',
        },
        {
          title: 'One-Stop-Shop',
          description:
            'Sparen Sie sich die stressige Suche. Wir bieten Ihnen alles, was Sie für Ihr Office-Management brauchen: Arbeitsplatz und Hardware, Software-Lizenzen und viele weitere Services für Ihr Büro.',
          image: 'website/onestopshop_f14ej3.svg',
          imageSize: '51',
          width: '2',
          className: 'my-4',
          numberTitle: '04',
        },
      ]}
    />
  )
}

export default BenefitSection
